import React, { useState } from "react";
import "./styles.scss";
import slackLogo from "../../assets/svg/slack.svg";
import { googleAuth, slackLogin } from "../../api/apis/auth";
import { useSelector } from "react-redux";
import { navigateAccToData } from "../../functions/common";
import { useNavigate } from "react-router-dom"
import Spinner from "../../components/Loader/Spinner";

export default function Steps() {

  const { loginStatus } = useSelector(state => state)
  const navigate = useNavigate();

  const [btnLoading, setBtnLoading] = useState(false)


  const goToNext = () => {
    navigateAccToData(navigate)
  }

  const loginToSlack = async () => {
    setBtnLoading(true);
    slackLogin(setBtnLoading)
  }

  return (
    <div className="steps_container">
      <div className="title_container">
        <h3 className="steps_title">Three Simple Steps to Get Started</h3>
      </div>
      <div className="steps_lists_container">
        <ol className="steps_list">
          <li className="steps_item">
            Sign Up through{" "}
            <span className="steps_highlight cursor__pointer" onClick={loginStatus ? goToNext : slackLogin}>Slack</span> or
            {" "}<span onClick={loginStatus ? goToNext : googleAuth} className="steps_highlight cursor__pointer"> Email</span>
          </li>
          <li className="steps_item">
            Link up with the platforms you'd like us to fetch data from.
          </li>
          <li className="steps_item">
            Begin posing questions to uncover insights from your data by mentioning our app, @Aha moment in your Slack channel. It's truly that straightforward.
          </li>
        </ol>
      </div>
      {!loginStatus ? <div className="cta_btn_container">
        <button onClick={loginToSlack} className="cta_container">
          {btnLoading ? <Spinner /> : <div className="cta_container">
            <img src={slackLogo} className="cta_img" alt="" />
            <p className="cta_text">Sign in with Slack</p>
          </div>}
        </button>
      </div> : ""}
      <div className="disclaimer_container">
        <p>
          <em>
            <sup>
              * AI chats may generate inaccurate responses. Consider checking
              important information.
            </sup>
          </em>
        </p>
      </div>
    </div>
  );
}
