import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { slackLogin, googleAuth } from "../../api/apis/auth";
import closeIcon from "../../assets/svg/close.svg";
import menuIcon from "../../assets/svg/menu-icon.svg";
import slackLogo from "../../assets/svg/slack.svg";
import "./styles.scss";
import { removeToken } from "../../functions/localstorage";
import { updateLoginStatus } from "../../redux/reducers/appreducer";
import { navigateAccToData } from "../../functions/common";
import Spinner from "../Loader/Spinner";
import Logo from "../Logo";

function NavbarComponent(props) {

  const [navbarToggle, setNavbarToggle] = useState("");

  const [activeTab, setActiveTab] = useState("account")
  const navbarRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [btnLoading, setBtnLoading] = useState("");

  const { loginStatus, userData, isLoading } = useSelector(state => state)

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setNavbarToggle(false);
    }
  };

  const logout = async () => {
    removeToken()
    dispatch(updateLoginStatus(false))
    navigate("/")
  }
  const dashboardClick = async () => {
    setBtnLoading("dashboard");
    navigateAccToData(navigate);
  }

  const googleSignin = () => {
    setBtnLoading("google");
    googleAuth();
    setBtnLoading("");
  }

  const loginWithSlack = () => {
    setBtnLoading("slack");
    slackLogin(setBtnLoading, true);
  }

  const changeTab = (tab) => {
    setActiveTab(tab)
    if (tab === "admin") {
      navigate("/super-admin")
    } else {
      navigate("/dashboard")
    }
  }

  useEffect(() => {
    if (location.pathname === "/super-admin") {
      setActiveTab("admin")
    }
  }, [])


  return (
    <div
      className="navbar_container"
      style={{ position: props?.pageName === "home" ? "fixed" : "absolute" }}
      ref={navbarRef}
    >
      <div className="logo_menu_container">
        <div className="logo_container">
          <Link to={"/"}>
            <Logo />
          </Link>
        </div>
        {props?.pageName === "dashboard" && userData?.isSuperAdmin && (
          <div
            className={`nav__tab__container`}
          >
            <button onClick={() => changeTab("admin")} className={`admin__nav__tab ${activeTab === "admin" ? "active__tab" : ""}`}>
              Admin Dash
            </button>
            <button onClick={() => changeTab("account")} className={`admin__nav__tab ${activeTab === "account" ? "active__tab" : ""}`}>
              Your Account
            </button>
          </div>
        )}
        {props?.pageName === "home" && (
          <div
            className={`menu_item_container ${navbarToggle === true
              ? "drawer_show"
              : navbarToggle === false
                ? "drawer_hide"
                : "default_drawer"
              } `}
          >
            <ul className="menu_container">
              <HashLink
                smooth
                to="/#how-it-works"
                onClick={() => setNavbarToggle(false)}
              >
                <li className="menu_item">How it works</li>
              </HashLink>
              <HashLink
                smooth
                to="/#connections"
                onClick={() => setNavbarToggle(false)}
              >
                <li className="menu_item">Connections</li>
              </HashLink>
              <HashLink
                smooth
                to="/#contact"
                onClick={() => setNavbarToggle(false)}
              >
                <li className="menu_item">Contact</li>
              </HashLink>
            </ul>
          </div>
        )}
      </div>
      {props?.pageName === "dashboard" && <button onClick={logout} className="logout__btn">Log out</button>}

      {props?.pageName === "home" && (
        <div className="btn_container">
          {!loginStatus ? <div className="d-flex gap-2 align-items-center">
            <button disabled={btnLoading !== ""} onClick={googleSignin} className="cta_container mob__signin__btn">
              {btnLoading === "google" ? <Spinner /> : <p className="cta_text">Sign in</p>}
            </button>
            <button disabled={btnLoading !== ""} onClick={loginWithSlack} className="cta_container hid__on__mob">
              {btnLoading === "slack" ? <Spinner /> : <div className="d-flex align-items-center justify-content-center gap-2">
                <img src={slackLogo} className="cta_img" alt="" />
                <p className="cta_text">Sign in with Slack</p>
              </div>
              }
            </button>
          </div> : <div className="d-flex gap-2 align-items-center">
            <button disabled={btnLoading !== "" || isLoading} onClick={dashboardClick} className="cta_container mob__signin__btn">
              {btnLoading === "dashboard" ? <Spinner /> : <p className="cta_text">Dashboard</p>}
            </button>
          </div>}
          <div>
            {navbarToggle === false || navbarToggle === "" ? (
              <img
                onClick={() => setNavbarToggle(true)}
                src={menuIcon}
                className="menu_icon"
                alt=""
              />
            ) : (
              <img
                onClick={() => setNavbarToggle(false)}
                src={closeIcon}
                className="menu_icon"
                alt=""
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default NavbarComponent;
